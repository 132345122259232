module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.centurylinkquote.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"chatEnabled":true,"analyticsId":"GTM-PC5T87","brandTokens":["CTL","TND","DTV"],"mapiBrandToken":"EMB","siteName":"centurylinkquote","alternateName":"centurylinkquote.com","siteURL":"https://www.centurylinkquote.com","defaultTitleTemplate":"","defaultPhone":"8885619173","phoneSymbol":"-","defaultPromoCode":"26102","smartyStreetsWebsiteKey":"17515623118088282","addressQualificationCode":"clearlink-ctl","convertProjectID":"10045742","vwoProjectID":"894940","cobra":{"sitePath":"centurylinkquote.com","buttonLink":"/cart","buttonText":"Order Now","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"centurylinkquote","short_name":"centurylinkquote","start_url":"/","background_color":"#48D597","theme_color":"#48D597","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"28a6ace9291e87b01d77ad9c07486849"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
